import React, { useState } from "react";
import { LogoSvg } from "../../constants/svgs";
import ThemeInput from "../input/ThemeInput";
import { useForm } from "react-hook-form";
import { validation, validationText } from "../../utils/app-constants";
import { Form, Button } from "react-bootstrap";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { useSigninMutation } from "../../store/apis/authApi";
import { toast } from "react-toastify";
import { errorMsg, successMsg } from "../../constants/msgs";
import { SetTokenLocalStorage, SetUserLocalStorage } from "../../utils/helper";
import { useNavigate } from "react-router-dom";
import ThemeSpinner from "../general/ThemeSpinner";
import Logo from "../../assets/img/logo.png";
import { useDispatch } from "react-redux";
import { setUserObject } from "../../store/services/authInfo";

const SigninForm = () => {
  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const [signin, { isLoading }] = useSigninMutation();
  const dispatch = useDispatch()
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const onSubmit = async (formData) => {
    try {
      let res = await signin(formData).unwrap();
      // console.log(res?.data?.token[0]?.api_token);
      successMsg(res?.message);
      dispatch(setUserObject(res?.data))
      SetUserLocalStorage(res?.data);
      SetTokenLocalStorage(res?.data?.token[0]?.api_token);
      navigate("/admin/chat-support");
    }
    catch (err) {
      errorMsg(err?.data?.errors);
    }
  };

  return (
    <div className="auth signin-form">
      <div className="header">
        <div className="svg-wrapper d-flex justify-content-center w-100 text-align-center mb-4">
          <img src={Logo} />
          {/* <LogoSvg /> */}
        </div>

        <div className="title">
          <h3 className="mb-1">Welcome to DUBBS Admin Panel! 👋</h3>
        </div>
      </div>

      <div className="content">
        <Form onSubmit={handleSubmit(onSubmit)}>
          <ThemeInput
            name="email"
            control={control}
            errors={errors}
            rules={{
              required: {
                value: true,
                message: validationText.emailRequired,
              },
              maxLength: {
                value: validation.emailMax,
                message: validationText.emailMax,
              },
              pattern: {
                value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                message: validationText.invalidEmail,
              },
            }}
            label="Email"
            placeholder="Enter your email"
            type="email"
            maxLength={validation.emailMax}
          />

          <div className="password-input">
            <ThemeInput
              name="password"
              control={control}
              errors={errors}
              rules={{
                required: {
                  value: true,
                  message: validationText.passwordRequired,
                },
                minLength: {
                  value: validation.passwordMin,
                  message: validationText.passwordMin,
                },
                maxLength: {
                  value: validation.passwordMax,
                  message: validationText.passwordMax,
                },
              }}
              label="Password"
              placeholder="Enter your password"
              type={showPassword ? "text" : "password"}
              minLength={validation.passwordMin}
              maxLength={validation.passwordMax}
            />
            <span className="eye-icon" onClick={handleShowPassword}>
              {showPassword ? <AiOutlineEye /> : <AiOutlineEyeInvisible />}
            </span>
          </div>

          <Button
            type="submit"
            disabled={isLoading}
            className="btn-solid btn-purple w-100 mt-4"
          >
            {isLoading ? <ThemeSpinner /> : " Sign in"}
          </Button>
        </Form>
      </div>
    </div>
  );
};

export default SigninForm;
