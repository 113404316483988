export const baseUrl = "https://jsonplaceholder.typicode.com";
export const StaggingUTL = "http://api.dubbgames.trangotechdevs.com:3005/";
export const INITIALTOKEN = "ee0a260d-417f-45b6-acd7-f003d3ad386d";
export const validation = {
  emailMax: 322,
  passwordMin: 5,
  passwordMax: 32,
  nameMin: 5,
  nameMax: 60,
  descriptionMax: 255,
  text: 120,
};

export const validationText = {
  emailRequired: "Email is required",
  titleRequired: "Title is required",
  emailMax: `Max length is ${validation.emailMax}`,
  invalidEmail: "Invalid email pattern",

  currentPasswordRequired: "Current password is required",
  newPasswordRequired: "New Password is required",
  confirmNewPasswordRequired: "Confirm new password is required",
  passwordRequired: "Password is required",
  passwordMin: `Max length is ${validation.passwordMin}`,
  passwordMax: `Max length is ${validation.passwordMax}`,

  nameRequired: "Name is required",
  nameMin: `Max length is ${validation.nameMin}`,
  nameMax: `Max length is ${validation.nameMax}`,

  descriptionRequired: "Description is required",
  descriptionMax: `Max length is ${validation.descriptionMax}`,

  numberAllowed: "Only numbers are allowed",
  numberAndDecimalAllowed: "Only numbers and decimal are allowed",

  questionRequired: "Question is required",
  answerRequired: "Answer is required",
};

export const localStorageConstant = {
  tokenKey: "game_token",
  userKey: "game_user",
};

export const firebaseConfig = {
  // apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  // authDomain: import.meta.env.VITE_FRIREBASE_AUTH_DOMAIN,
  // projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  // storageBucket: import.meta.env.VITE_STORAGE_BUCKET,
  // messagingSenderId: import.meta.env.VITE_MESSAGING_SENDER_ID,
  // appId: import.meta.env.VITE_FIREBASE_APP_ID,
};

export const modalType = {
  view: "view",
  edit: "edit",
  delete: "delete",
  block: "block",
};

export const apiUrls = {
  signin: "/admin/login",
  signout: "/admin/signout",
  changePassword: "/admin/change-password",
  tcpp: "/admin/TcPp",
  getTcpp: "/admin/getTcPp",
  getAllUsers: "/admin/get-all-users",
  blockUnblockUser: "/admin/blockunblock",
  deleteUser: "/admin/delete-account",
  dashboardData: "/admin/dashboard",
  graph: "/admin/graph",
  sendPush: "/admin/send-push",
  faq: "/admin/faq",
  reportTypes: "/admin/report-types",
};
