import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import StatsCard from "../components/dashboard/StatsCard";
import { LuUserCircle2 } from "react-icons/lu";
import { RiParentFill } from "react-icons/ri";
import { FaChildReaching } from "react-icons/fa6";
import { MdInsertPageBreak } from "react-icons/md";
import ThemeChart from "../components/general/ThemeChart";
import elipse from "../assets/img/user.png";
import {
  useDashboardDataQuery,
  useGraphDataQuery,
} from "../store/apis/userApi";
import ThemeDataTable from "../components/general/ThemeDataTable";
import { userColumns } from "../utils/columns-constants";
import { useDispatch, useSelector } from "react-redux";
import { getFilteredData } from "../utils/helper";
import {
  setPage,
  setPaginatedData,
  setPerPage,
} from "../store/slices/userSlice";
import { useForm } from "react-hook-form";
import { faker } from "@faker-js/faker";
import ViewModal from "../components/modal/ViewModal";
import RidersDetailModal from "../components/RidersDetailModal";
import DistributorDetailModal from "../components/DistributorViewModal";
import ReportsDetailModal from "../components/ReportViewModal";

// Generate dummy data using faker
function createRandomUser() {
  return {
    userId: faker.string.uuid(),
    username: faker.internet.userName(),
    email: faker.internet.email(),
    avatar: faker.image.avatar(),
    password: faker.internet.password(),
    birthdate: faker.date.birthdate(),
    registeredAt: faker.date.past(),
    description: faker.lorem.lines(), // Add description field
    phonenumber: faker.phone.number(),
  };
}

const REPORTSDATA = faker.helpers.multiple(createRandomUser, {
  count: 25,
});
console.log(REPORTSDATA, 'dsad');


const DashboardPage = () => {
  const { user } = useSelector(state => state.authInfo)
  console.log(user, 'unser context');

  const {
    register,
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ mode: "onChange" });
  const { isLoading, data: apiData } = useDashboardDataQuery();
  const dispatch = useDispatch();
  const searchText = watch("searchText");
  const [isOpen, setIsOpen] = useState({ type: null, open: false });
  const [selectedMonth, setSelectedMonth] = useState(1);
  const [expanded, setExpanded] = useState({});

  const [showData, setData] = useState([]);
  const data = {
    labels: ["January", "February", "March", "April", "May", "June", "July"],
    datasets: [
      {
        label: "Dataset 1",
        data: [65, 59, 80, 81, 56, 55, 40],
        fill: false,
        backgroundColor: "rgba(75,192,192,0.2)",
        borderColor: "rgba(75,192,192,1)",
      },
      {
        label: "Dataset 2",
        data: [28, 48, 40, 19, 86, 27, 90],
        fill: false,
        backgroundColor: "rgba(153,102,255,0.2)",
        borderColor: "rgba(153,102,255,1)",
      },
    ],
  };
  const truncateText = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length <= wordLimit) return text;
    return words.slice(0, wordLimit).join(" ") + "...";
  };

  const handleViewClick = (userId) => {
    setExpanded((prev) => ({
      ...prev,
      [userId]: !prev[userId],
    }));
  };

  const legends = ["Weekdays", "Normal Days"]; // Your custom labels
  const chartOptions = {
    plugins: {
      legend: {
        position: "top",
        align: "center",
        labels: {
          usePointStyle: true,
          generateLabels: function (chart) {
            return chart.data.datasets.map((dataset, index) => ({
              text: `${legends[index]}`, // Custom label text
              fillStyle: dataset.backgroundColor,
              hidden: !chart.isDatasetVisible(index),
              lineCap: dataset.borderCapStyle,
              lineDash: dataset.borderDash,
              lineDashOffset: dataset.borderDashOffset,
              lineJoin: dataset.borderJoinStyle,
              strokeStyle: dataset.borderColor,
              dataset: dataset,
              index: index,
            }));
          },
          font: {
            size: 12.16, // Change the font size
          },
        },
      },
      title: {
        display: true,
        text: "Total Revenue",
        position: "top",
        align: "start",
        font: {
          size: 19.19, // Change the font size
          weight: 700,
          family: "Gilroy",
        },
      },
    },
    elements: {
      point: {
        pointStyle: "circle", // Set the point style to 'circle'
        radius: 4, // Set the size of the point elements
        backgroundColor: "blue", // Change the point color (if needed)
      },
    },
  };

  let months = [
    { key: 1, value: "January" },
    { key: 2, value: "February" },
    { key: 3, value: "March" },
    { key: 4, value: "April" },
    { key: 5, value: "May" },
    { key: 6, value: "June" },
    { key: 7, value: "July" },
    { key: 8, value: "August" },
    { key: 9, value: "September" },
    { key: 10, value: "October" },
    { key: 11, value: "November" },
    { key: 12, value: "December" },
  ];
  const MonthDropdown = () => {
    return (
      <select
        className="month-dropdown"
        onChange={(e) => setSelectedMonth(e.target.value)}
        value={selectedMonth}
      >
        {months?.map((dt) => (
          <option value={dt?.key}>{dt?.value}</option>
        ))}
      </select>
    );
  };
  return (
    <div className="pages dashboard-page">
      <div className="dashboard-count-table d-flex">
        <div className="user">
          <div className="head-user">Total Users</div>
          <div className="head-count">18,200</div>
        </div>
        <div className="distributors">
          <div className="head-user">Total Distributors</div>
          <div className="head-count">18,200</div>
        </div>
        <div className="shop-owner">
          <div className="head-user">Shop Owner</div>
          <div className="head-count">18,200</div>
        </div>
        <div className="riders">
          <div className="head-user">Total Riders</div>
          <div className="head-count">18,200</div>
        </div>
      </div>
      <Row className="mt-4">
        <Col xs={8}>
          <div className="chart-container-custom">
            <ThemeChart type="line" data={data} chartOptions={chartOptions} />
            <MonthDropdown />
          </div>

          <Row className="mt-3">
            <ThemeDataTable
              columns={userColumns(setIsOpen, dispatch)}
              rows={getFilteredData(REPORTSDATA, searchText)}
              isLoading={isLoading}
              setPage={setPage}
              setPerPage={setPerPage}
            />
          </Row>
        </Col>
        <Col xs={4}>
          <div className="report">
            <div className="header">
              <h1 className="mb-4 mt-1">
                Report <span>(Total 12)</span>
              </h1>
              <div className="horizontol-line"></div>
            </div>
            <div className="sec-content">
              {REPORTSDATA?.map((dt) => (
                <>
                  <div className="data mt-4">
                    <div className="d-flex head-data mb-2">
                      <img src={dt?.avatar ? dt?.avatar : elipse} />
                      <div className="name">{dt?.username}</div>
                    </div>
                    <div className="content">
                      {expanded[dt.userId]
                        ? dt.description
                        : truncateText(dt.description, 10)}
                      <span
                        className="cursor"
                        onClick={() => handleViewClick(dt.userId)}
                      >
                        {expanded[dt.userId] ? "Show Less" : "View"}
                      </span>
                    </div>
                  </div>
                  <div className="horizontol-line"></div>
                </>
              ))}
            </div>
          </div>
        </Col>

        {/* <Col xs={6}>
          <ThemeChart type="line" data={chartData} />
        </Col> */}
      </Row>
      <ViewModal
        children={<ReportsDetailModal />}
        title={"Report Details"}
        size={"md"}
        isOpen={isOpen}
        setIsOpen={setIsOpen}
      />
    </div>
  );
};

export default DashboardPage;
