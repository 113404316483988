import React from 'react';
import { Col, Row } from 'react-bootstrap';
import Inbox from './Inbox';
import ChatThreads from './chatThreads';
import _ from 'lodash';
import { useChatContext } from '../../context/ChatContext';
import { useSelector } from 'react-redux';


const MessageWrapper = () => {

    const { chatRoom } = useChatContext()

    return (
        <div className={"Layout"}>
            <Row className={"row"}>
                <Col
                    className={"col"}
                    md={4}
                >
                    <ChatThreads />
                </Col>
                <Col
                    className={"col"}
                    md={8}
                >
                    {_.isEmpty(chatRoom) ? <></> :
                        <Inbox key={chatRoom?.slug || ''} />
                    }
                </Col>

            </Row>
        </div>
    )
}

export default MessageWrapper;