import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { useSocket } from "../socket/socketConext";


export const ChatContext = createContext();

export function useChatContext() {
    return useContext(ChatContext)
}


export default function ChatContextProvider({ children }) {
    const socket = useSocket();
    const [search, setSearch] = useState('');
    const { isLoading, user } = useSelector(state => state.authInfo)
    const isSocketConnected = useSelector(state => state.socket.isSocketConnected)

    const INITIAL_CHAT_THREAD = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])

    const INITIAL_GROUP_THREAD = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: []
        }
    }, [])



    const [chatRoom, setChatRoom] = useState({})
    const [isGroup, setIsGroup] = useState(false)


    const [chatThread, setChatThread] = useState(INITIAL_CHAT_THREAD)
    const [groupThread, setGroupThread] = useState(INITIAL_GROUP_THREAD)

    const allChatThreads = useMemo(() => {
        if (!search) return chatThread

        return {
            ...chatThread,
            data: chatThread?.data.filter(item => item.room_name.toLowerCase().includes(search.toLowerCase()))
        }

    }, [chatThread, search])


    // useEffect(() => {
    //     if (isSocketConnected) {
    //         console.log("Listening for loadChatHistory_");

    //         const slug = {
    //             chat_room_slug: "8c50de7e-4561-473f-9ceb-d7d608e0a795",
    //         };

    //         // socket.emit('loadChatHistory_', slug);

    //         const handleChatHistory = (data) => {
    //             setChatHistory(data);
    //         };

    //         socket.on('loadChatHistory_', handleChatHistory);

    //         return () => {
    //             if (isSocketConnected) {
    //                 // socket.dispose('loadChatHistory_', handleChatHistory);
    //                 console.log("Stopped listening to loadChatHistory_");
    //             }
    //         };
    //     }
    // }, [isSocketConnected, socket]);


    useEffect(() => {
        if (isSocketConnected) {
            console.log("Listening for  Get Chat Threads");

            socket.on('getChatThreads_', (data) => {
                console.log(data, 'data market');

                setChatThread(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: data?.data || []
                    }
                })
            })
        }


        return () => {
            if (isSocketConnected) {
                socket.dispose('getChatThreads_')
            }
        }

    }, [isSocketConnected])

    useEffect(() => {
        if (!isSocketConnected) return
        socket.emit('_getChatThreads')
    }, [isSocketConnected])


    const handleTabChange = (isGroup) => {
        setChatRoom({})
        setSearch('')
    }

    const resetMessageCount = (chat_room_slug) => {
        setChatThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== chat_room_slug) return item
                    return {
                        ...item,
                        unread_message_count: 0
                    }
                })
            }
        })
        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== chat_room_slug) return item
                    return {
                        ...item,
                        unread_message_count: 0
                    }
                })
            }
        })
    }

    const updateLastMessagePayload = (data) => {
        setChatThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== data?.chat_room_slug) return item
                    return {
                        ...item,
                        message: {
                            ...item?.message,
                            message_type: data?.message_type,
                            message: data?.message,
                            createdAt: data?.message_timestamp,
                        },
                    }
                })
            }
        })

        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data.map(item => {
                    if (item.chat_room_slug !== data?.chat_room_slug) return item
                    return {
                        ...item,
                        message: {
                            ...item?.message,
                            message_type: data?.message_type,
                            message: data?.message,
                            createdAt: data?.message_timestamp,
                        },
                    }
                })
            }
        })
    }


    const handleAddGroup = (groupData) => {
        setGroupThread(prev => {
            return {
                ...prev,
                data: [
                    {
                        ...groupData,
                        is_admin: groupData.user_slug === user.slug
                    },
                    ...prev?.data,
                ]
            }
        })
    }

    const handleRemoveGroup = (group_slug) => {
        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data?.filter(item => item.chat_room_slug !== group_slug),
            }
        })
    }

    const handleGroupMemberUpdate = (group_slug, data) => {
        if (!_.isEmpty(chatRoom) && (chatRoom?.slug === group_slug)) {
            setChatRoom(prev => {
                return {
                    ...prev,
                    members: data
                }
            })
        }

        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data?.map(item => {
                    if (item.chat_room_slug !== group_slug) return item;
                    return {
                        ...item,
                        members: data
                    }
                })
            }
        })
    }

    const handleGroupDetailsUpdate = (data) => {
        const group_slug = data?.payload?.chat_room_slug
        const payload = data?.data;
        if (!_.isEmpty(chatRoom) && (chatRoom?.slug === group_slug)) {
            setChatRoom(prev => {
                return {
                    ...prev,
                    room_name: payload.title || prev.room_name,
                    image_url: payload.image_url || prev.image_url
                }
            })
        }
        setGroupThread(prev => {
            return {
                ...prev,
                data: prev?.data?.map(item => {
                    if (item.chat_room_slug !== group_slug) return item;
                    return {
                        ...item,
                        room_name: payload?.title,
                        image_url: payload?.image_url
                    }
                })
            }
        })
    }

    return (
        <ChatContext.Provider value={{
            search,
            setSearch,
            chatThread: allChatThreads,
            groupThread,
            chatRoom,
            setChatRoom,
            handleTabChange,
            resetMessageCount,
            updateLastMessagePayload,
            handleAddGroup,
            handleGroupMemberUpdate,
            handleGroupDetailsUpdate
        }}>
            {children}
        </ChatContext.Provider>
    )

}