import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import { authInfoReducer } from './services/authInfo';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: [],
};

export default persistConfig;
