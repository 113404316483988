import React, { useContext, useEffect } from "react";
import socket from "./socket";
// import { getToken, removeToken } from "../Data/services/localStorageService";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../store/services/authInfo";
import { socketConntected } from "../store/services/socketSlice";
import { GetTokenFromLocalStorage } from "../utils/helper";



const SocketContext = React.createContext();

export function useSocket() {
    return useContext(SocketContext);
}

export function SocketProvider({ children }) {
    const { user } = useSelector(state => state.authInfo)
    const isSocketConnected = useSelector(state => state.socket.isSocketConnected)

    const dispatch = useDispatch();

    useEffect(() => {
        const token = GetTokenFromLocalStorage()
        if (!isSocketConnected) {
            socket.connectToSocket(
                token,
                () => {
                    dispatch(logout())
                    // removeToken();
                },
                () => {
                    console.log("Socket connected successfully")
                    dispatch(socketConntected())
                });
        }

        return () => {
            if (!isSocketConnected) {
                socket?.disconnect();
            }
        }
    }, [user])

    useEffect(() => {
        if (isSocketConnected) {
            socket.on('newRoom_', (data) => {
                socket.emit('_joinRoom', data?.data?.chat_room_slug)
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('newRoom_')
            }

        }
    }, [isSocketConnected])


    return <SocketContext.Provider value={socket}>
        {children}
    </SocketContext.Provider>
}