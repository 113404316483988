import { configureStore } from '@reduxjs/toolkit';
import { persistStore } from 'redux-persist';
import persistedReducer from '.';
import { authApi } from './apis/authApi';
import { rehydrateState } from './services/authInfo';
import { apiSlice } from './services/apiSlice';

export const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            serializableCheck: {
                ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
            },
        }).concat(apiSlice.middleware)
            .concat(authApi.middleware)
});