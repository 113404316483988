import React, { useEffect, useState } from "react";
import { FaBars } from "react-icons/fa";
import ProfilePlaceholder from "../../assets/img/profile-placeholder.jpg";
import UserImg from "../../assets/img/user.png";
import Notifications from 'react-notifications-menu';
import { Dropdown, DropdownButton } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import LogoutModal from "../modal/LogoutModal";
import { GetUserFromLocalStorage } from "../../utils/helper";
import { IsRead, NotiSvg } from "../../constants/svgs";
import admin from "../../assets/img/admin.png";
import notiIcon from "../../assets/img/noti.svg"
import { faker } from "@faker-js/faker";

const DashboardHeader = ({ handleSidebar }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [localUser, setLocalUser] = useState(null);
  const user = GetUserFromLocalStorage()
  console.log(user, 'in dashboard');

  const getLocalUser = () => {
    let user = GetUserFromLocalStorage();
    setLocalUser(user);
  };
  useEffect(() => {
    getLocalUser();
  }, []);

  function createRandomUser() {
    return {
      userId: faker.string.uuid(),
      username: faker.internet.userName(),
      email: faker.internet.email(),
      avatar: faker.image.avatar(),
      password: faker.internet.password(),
      birthdate: faker.date.birthdate(),
      registeredAt: faker.date.past(),
      description: faker.lorem.lines(), // Add description field
      phonenumber: faker.phone.number(),
      orderId: faker.number.int(4564),
      itemId: faker.number.int(44),
      amount: faker.number.int(454),
      date: faker.date.anytime(),
      status: faker.number.int({ min: 1, max: 3 }),
    };
  }
  const [notiData, setNotiData] = useState([]);

  useEffect(() => {
    let customerRecord = faker.helpers.multiple(createRandomUser, {
      count: 25,
    });
    // console.log(customerRecord, 'customerRecord');

    const mappedNotiData = customerRecord.map((record, index) => ({
      message: (
        <div key={index} className="data">
          <div className="data-info">
            <div className="tittle">{record.username}</div>
            <div className="mesg">
              {record?.description}
            </div>
          </div>
          {/* <div className="isread">
            <IsRead />
          </div> */}
        </div>
      ),
    }));

    setNotiData(mappedNotiData);
  }, []);

  return (
    <>
      <div className="dashboard-header">
        <div className="page-name">Admin</div>
        <div className="side-data d-flex">
          <div className="noti-icon cursor">

            {/* <NotiIcon /> */}
            <div>
              <Notifications
                style={{ color: 'red' }}
                data={notiData}
                // fetchData 
                // prop to fetch data on pagination
                icon={notiIcon} // Use the FaRegBell icon component here
                header={{
                  title: "Notifications",
                  option: {
                    // other options...
                  }
                }}
                markAsRead={(notiData) => {
                  console.log(notiData);
                }}
              />
            </div>
          </div>
          <DropdownButton
            title={
              <img
                src={admin}
                alt=""
                onError={(e) => (e.target.src = ProfilePlaceholder)}
              />
            }
          >
            <NavLink to="/admin/change-password">Change Password</NavLink>
            <h6 className="cursor" onClick={() => setIsOpen(true)}>
              Logout
            </h6>
          </DropdownButton>
          <div className="admin-name">{user?.full_name}</div>
        </div>
        {/* <FaBars className='ms-3 cursor' onClick={handleSidebar} /> */}
      </div>

      <LogoutModal isOpen={isOpen} setIsOpen={setIsOpen} />
    </>
  );
};

export default DashboardHeader;
