export const CardTitle = ({
    text = '',
    classes = ''

}) => {
    return (
        <h6
            className={`${"h6-CardTitle"} ${classes} `}
        >{text}</h6>
    )
}

export const Description = ({
    text = '',
    classes = '',
    onClick = () => { }

}) => {
    return (
        <p
            className={`${"p-description"}"} ${classes} fs-7`}
            onClick={onClick}
        >{text}</p>
    )
}

export const CardDescription = ({
    text = '',
    classes = '',

}) => {
    return (
        <p
            className={`${"p-description"} ${classes} fs-10`}
        >
            {text}</p>
    )
}


export const Title = ({
    text = '',
    classes = ''

}) => {
    return (
        <h1
            className={`${"h1-header"} ${classes}`}
        >{text}</h1>
    )
}

export default function BorderedCard({ classes = '', children }) {
    return (
        <div className={`${"BorderedCard"} ${classes}`}>
            {children}
        </div>
    )
}