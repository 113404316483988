import React, { useEffect, useState } from 'react';
import BorderedCard, { CardTitle, Description } from './Component';
import user from "../../assets/img/user.png";
import { useSelector } from 'react-redux';
import { useChatContext } from '../../context/ChatContext';
import { useSocket } from '../../socket/socketConext';
import axios from 'axios';
import { Spinner } from 'react-bootstrap';
// import { useSocket } from '../../socket/socketConext';

const ChatThreads = ({ setUser }) => {
    const { chatRoom, setChatRoom, search, setSearch, isGroup, handleTabChange, chatThread, groupThread, resetMessageCount, handleAddGroup } = useChatContext()

    // useEffect(() => {
    //     const handleChatThreads = (response) => {
    //         if (response.code === 200) {
    //             setChatThreads(response.data);
    //         }
    //     };

    //     // socketIo.on("getChatThreads_", handleChatThreads);
    //     socketIo.on("getGroupThreads_", handleChatThreads);

    //     return () => {
    //         socketIo.dispose("getChatThreads_", handleChatThreads);
    //         socketIo.dispose("getGroupThreads_", handleChatThreads);
    //     };
    // }, [socket]);



    const handleThreadClick = (item) => {
        setChatRoom(item)
    }
    return (
        <div className={"ChatThreads"}>
            <div className={"titleContainer"}>
                {/* <SubTitle
                    classes='akiraFont'
                    text={'Customer Support'}
                /> */}
                {/* <Description
                    text={'Lorem Ipsum is simply dummy text of the printing and typesetting industry.'}
                    classes='mt-1 fs-8'
                /> */}
            </div>
            <div className={"threads"}>
                {chatThread?.isLoading ? <Spinner /> : chatThread?.data?.map((item, index) => {
                    return (
                        <div
                            onClick={() => handleThreadClick(item)}
                        >
                            <BorderedCard classes='my-2 overflow-visible' key={item}>
                                <div
                                    className={"thread"}
                                >
                                    <img
                                        className={"userImage"}
                                        src={item?.image_url}
                                        alt='User Profile'
                                    />
                                    <div className={"userInfo"}>
                                        <div className={"nameContainer"}>
                                            <CardTitle
                                                text={item?.room_name}
                                                classes='fs-6 lc-1'
                                            />
                                            {/* <span className={"indicator"}></span> */}
                                        </div>
                                        <p className={`${"message"} mt-1 pe-1 lc-1`}>
                                            {item?.message?.message}
                                        </p>
                                    </div>
                                </div>
                            </BorderedCard>
                        </div>
                    )
                })}

            </div>
        </div>
    )
}
export default ChatThreads