import React, { useState } from "react";
import { LogoSvg, Orders } from "../../constants/svgs";
import { IoClose } from "react-icons/io5";
import { RxDashboard } from "react-icons/rx";
import { AiOutlineUser } from "react-icons/ai";
import { NavLink, useLocation } from "react-router-dom";
import { HiChevronDown } from "react-icons/hi";
import { RiFileList2Line } from "react-icons/ri";
import { RiLogoutBoxRLine } from "react-icons/ri";
import { Button, Collapse } from "react-bootstrap";
import { MdOutlineCircle } from "react-icons/md";
import LogoutModal from "../modal/LogoutModal";
import { PiShoppingCartBold } from "react-icons/pi";
import { TbHomeSignal } from "react-icons/tb";
import { AiOutlineShop } from "react-icons/ai";
import { FiTruck } from "react-icons/fi";
import { LiaUserAstronautSolid } from "react-icons/lia";
import { IoMdArrowForward } from "react-icons/io";
import { BsChatRightDotsFill } from "react-icons/bs";

import { PiUsersThree } from "react-icons/pi";

import logo from "../../assets/img/logo.png";

const DashboardSidebar = ({ closeSidebar, activePage }) => {
  const [open, setOpen] = useState(false);
  const [isLogoutModalOpen, setIsLogoutModalOpen] = useState(false);

  const location = useLocation();

  const nestedActive = (path) => {
    if (location.pathname.includes(path)) {
      return "active-light";
    } else {
      return "";
    }
  };
  const menuItems = [
    // { to: "/admin/dashboard", label: "Dashboard", icon: <RxDashboard />, page: "dashboard" },
    // { to: "/admin/orders", label: "Orders", icon: <PiShoppingCartBold />, page: "orders" },
    // { to: "/admin/customers", label: "Customers", icon: <PiUsersThree />, page: "customers" },
    // { to: "/admin/distributors", label: "Distributors", icon: <TbHomeSignal />, page: "distributors" },
    // { to: "/admin/shopowners", label: "Shop Owners", icon: <AiOutlineShop />, page: "shopowners" },
    // { to: "/admin/riders", label: "Riders", icon: <FiTruck />, page: "riders" },
    { to: "/admin/chat-support", label: "Chat Support", icon: <BsChatRightDotsFill />, page: "chat" },
    // { to: "/admin/reports", label: "Reports", icon: <LiaUserAstronautSolid />, page: "reports" },
  ];
  return (
    <>
      <div className="dashboard-sidebar">
        <div className="header d-flex align-items-center justify-content-between mb-5">
          <div className="logo d-flex align-items-center justify-content-center w-100">
            {/* <LogoSvg /> */}
            <img src={logo} alt={"logo"} width={40} height={40} />
            {/* <h4 className="ms-3">
              <b className="text-black">Bibo Admin Panel</b>
            </h4> */}
          </div>

          <IoClose
            size={22}
            className="text-black d-block d-md-none"
            onClick={() => closeSidebar("abc")}
          />
        </div>

        <div className="link-wrapper">
          <ul>

            {menuItems.map((item, index) => (
              <li className="mb-3" key={index}>
                <NavLink to={item.to} onClick={() => closeSidebar(item.page)}>
                  <div className="icon-wrapper">{item.icon}</div>
                  <span className={`${activePage === item.page ? "active-li" : ""}`}>{item.label}</span>
                </NavLink>
              </li>
            ))}
            <li className="mb-3 li-logout">
              <Button
                type="submit"
                onClick={() => setIsLogoutModalOpen(true)}
                // disabled={isLoading}
                className="btn-solid btn-p mt-4 logout"
              >
                logout <IoMdArrowForward size={25} />

              </Button>
            </li>
          </ul>
        </div>
      </div>

      <LogoutModal
        isOpen={isLogoutModalOpen}
        setIsOpen={setIsLogoutModalOpen}
        size={'sm'}
      />
    </>
  );
};

export default DashboardSidebar;
