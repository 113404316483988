import React, { useState } from "react";
import DashboardSidebar from "../components/dashboard/DashboardSidebar";
import { Col, Container, Row } from "react-bootstrap";
import DashboardHeader from "../components/dashboard/DashboardHeader";

const DashboardLayout = ({ children }) => {
  const [active, setActive] = useState(false);
  const [activePage, setActivePage] = useState("");

  const handleSidebar = () => {
    setActive(!active);
  };

  const closeSidebar = (pageName) => {
    setActive(false);
    setActivePage(pageName);
  };

  return (
    <div className="dashboard-layout">
      <aside className={`sidebar-wrapper ${active ? "active" : ""} `}>
        <DashboardSidebar closeSidebar={closeSidebar} activePage={activePage} />
      </aside>

      <section className="dasboard-content-wrapper">
        <Row>
          <Col xs={12}>
            <DashboardHeader handleSidebar={handleSidebar} />
          </Col>
          <Col xs={12} className="mt-4">
            <Container fluid>{children}</Container>
          </Col>
        </Row>
      </section>
    </div>
  );
};

export default DashboardLayout;
