import { FaRegEye } from "react-icons/fa";
import { RiDeleteBin7Line, RiEdit2Line } from "react-icons/ri";
import { baseUrl, modalType } from "./app-constants";
import ProfilePlaceholder from "../assets/img/profile-placeholder.jpg";
import { TbUserCog } from "react-icons/tb";
import { setSelectedData } from "../store/slices/userSlice";
import { setSelectedFaqData } from "../store/slices/faqSlice.js";
import elipse from "../assets/img/user.png";
import { useState } from "react";
import { tr } from "@faker-js/faker";

export const userColumns = (setIsOpen, dispatch) => {
  return [
    {
      key: "name",
      name: "Name",
      sortable: true,
      selector: (row) => (
        <div className="name-column">
          <img
            src={elipse}
            alt="User"
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "25px",
            }}
          />
          {row?.username || "-"}
        </div>
      ),
    },
    {
      key: "email",
      name: "Email",
      sortable: true,
      selector: (row) => row?.email || "-",
    },
    {
      key: "phone",
      name: "Phone",
      sortable: true,
      selector: (row) => row?.phonenumber || "-",
    },
    {
      key: "status",
      name: "status",
      sortable: true,
      selector: (row) => <div className="column-status-pending">Pending</div>,
    },
    {
      key: "action",
      name: "Action",
      selector: (row) => (
        <div
          className="theme-action view-column cursor"
          onClick={() => {
            setIsOpen({ type: modalType.view, open: true });
          }}
        >
          View
          {/* <FaRegEye
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.view, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
          {/* <TbUserCog
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.block, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
          {/* <RiDeleteBin7Line
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.delete, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
        </div>
      ),
    },
  ];
};

export const faqColumns = (setIsOpen, dispatch) => {
  return [
    {
      key: "question",
      name: "Question",
      sortable: true,
      selector: (row) => row?.question || "-",
    },
    {
      key: "answer",
      name: "Answer",
      sortable: true,
      selector: (row) => row?.answer || "-",
    },
    {
      key: "Created At",
      name: "Created At",
      sortable: true,
      selector: (row) => row?.createdAt.toString() || "-",
    },
    {
      key: "action",
      name: "Action",
      selector: (row) => (
        <div className="theme-action">
          <FaRegEye
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.view, open: true });
              dispatch(setSelectedFaqData(row));
            }}
          />
          <TbUserCog
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.edit, open: true });
              dispatch(setSelectedFaqData(row));
            }}
          />
          <RiDeleteBin7Line
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.delete, open: true });
              dispatch(setSelectedFaqData(row));
            }}
          />
        </div>
      ),
    },
  ];
};

export const reportColumns = (setIsOpen, dispatch) => {
  return [
    {
      key: "types",
      name: "Types",
      sortable: true,
      selector: (row) => row?.text || "-",
    },
    {
      key: "Created At",
      name: "Created At",
      sortable: true,
      selector: (row) => row?.createdAt.toString() || "-",
    },
    {
      key: "action",
      name: "Action",
      selector: (row) => (
        <div className="theme-action">
          <FaRegEye
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.view, open: true });
              dispatch(setSelectedData(row));
            }}
          />
          {/*<TbUserCog*/}
          {/*    className='icon'*/}
          {/*    onClick={() => {*/}
          {/*        setIsOpen({type: modalType.block, open: true})*/}
          {/*        dispatch(setSelectedData(row))*/}
          {/*    }}*/}
          {/*/>*/}
          <RiDeleteBin7Line
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.delete, open: true });
              dispatch(setSelectedData(row));
            }}
          />
        </div>
      ),
    },
  ];
};

export const orderColumns = (setIsOpen, dispatch) => {
  return [
    {
      key: "orderid",
      name: "orderid",
      sortable: true,
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "customerid",
      name: "customer ID",
      sortable: true,
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "rollid",
      name: "Roll ID",
      sortable: true,
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "shopownerid",
      name: "Shopowner ID",
      sortable: true,
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "items",
      name: "Items",
      sortable: true,
      selector: (row) => row?.itemId || "-",
    },
    {
      key: "date",
      name: "Date",
      sortable: true,
      selector: (row) => new Date(row?.date).toLocaleDateString() || "-",
    },
    {
      key: "amount",
      name: "Amount",
      sortable: true,
      selector: (row) => row?.amount || "-",
    },
    {
      key: "status",
      name: "Status",
      sortable: true,
      selector: (row) => (
        <div
          className={`${
            row?.status == 2
              ? "column-status-pending"
              : row?.status == 1
              ? "column-status-completed"
              : "canceled"
          }`}
        >
          {row?.status == 2
            ? "Pending"
            : row?.status == 1
            ? "Completed"
            : "Cancelled"}
        </div>
      ),
    },
  ];
};
export const customerColumns = (setIsOpen, dispatch, setIsBlockOpen) => {
  return [
    {
      key: "name",
      name: "Name",
      sortable: true,
      width: "250px", // Adjust the width as needed
      selector: (row) => (
        <div className="name-column">
          <img
            src={elipse}
            alt="User"
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "25px",
            }}
          />
          {row?.username || "-"}
        </div>
      ),
    },
    {
      key: "customerid",
      name: "Customer ID",
      sortable: true,
      width: "150px", // Adjust the width as needed
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "email",
      name: "Email",
      sortable: true,
      width: "300px", // Adjust the width as needed
      selector: (row) => row?.email || "-",
    },
    {
      key: "phone",
      name: "Phone",
      sortable: true,
      width: "250px", // Adjust the width as needed
      selector: (row) => row?.phonenumber || "-",
    },
    {
      key: "totalorders",
      name: "Total Orders",
      sortable: true,
      width: "150px", // Adjust the width as needed
      selector: (row) => row?.itemId || "-",
    },
    {
      key: "action",
      name: "Action",
      width: "100px", // Adjust the width as needed
      selector: (row) => (
        <ActionMenu
          row={row}
          setIsOpen={setIsOpen}
          setIsBlockOpen={setIsBlockOpen}
        />
      ),
    },
  ];
};

export const distributorsColumns = (setIsOpen, dispatch) => {
  return [
    {
      key: "name",
      name: "Name",
      sortable: true,
      width: "250px", // Adjust the width as needed
      selector: (row) => (
        <div className="name-column">
          <img
            src={elipse}
            alt="User"
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "25px",
            }}
          />
          {row?.username || "-"}
        </div>
      ),
    },
    {
      key: "customerid",
      name: "Customer ID",
      sortable: true,
      width: "150px", // Adjust the width as needed
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "email",
      name: "Email",
      sortable: true,
      width: "300px", // Adjust the width as needed
      selector: (row) => row?.email || "-",
    },
    {
      key: "phone",
      name: "Phone",
      sortable: true,
      width: "250px", // Adjust the width as needed
      selector: (row) => row?.phonenumber || "-",
    },
    {
      key: "postalcode",
      name: "Postal Code",
      sortable: true,
      width: "150px", // Adjust the width as needed
      selector: (row) => row?.itemId || "-",
    },
    {
      key: "action",
      name: "Action",
      width: "100px", // Adjust the width as needed
      selector: (row) => <ActionMenu row={row} setIsOpen={setIsOpen} />,
    },
  ];
};
export const shopOwnerColumns = (setIsOpen, dispatch) => {
  return [
    {
      key: "name",
      name: "Name",
      sortable: true,
      width: "250px", // Adjust the width as needed
      selector: (row) => (
        <div className="name-column">
          <img
            src={elipse}
            alt="User"
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "25px",
            }}
          />
          {row?.username || "-"}
        </div>
      ),
    },
    {
      key: "customerid",
      name: "Customer ID",
      sortable: true,
      width: "150px", // Adjust the width as needed
      selector: (row) => row?.orderId || "-",
    },
    {
      key: "email",
      name: "Email",
      sortable: true,
      width: "300px", // Adjust the width as needed
      selector: (row) => row?.email || "-",
    },
    {
      key: "phone",
      name: "Phone",
      sortable: true,
      width: "250px", // Adjust the width as needed
      selector: (row) => row?.phonenumber || "-",
    },
    {
      key: "postalcode",
      name: "Postal Code",
      sortable: true,
      width: "150px", // Adjust the width as needed
      selector: (row) => row?.itemId || "-",
    },
    {
      key: "action",
      name: "Action",
      width: "100px", // Adjust the width as needed
      selector: (row) => <ActionMenu row={row} setIsOpen={setIsOpen} />,
    },
  ];
};

export const ridersColumns = (setIsOpen, dispatch, activeFilter) => {
  return [
    {
      key: "name",
      name: "Name",
      sortable: true,
      width: "300px",

      selector: (row) => (
        <div className="name-column">
          <img
            src={elipse}
            alt="User"
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "25px",
            }}
          />
          {row?.username || "-"}
        </div>
      ),
    },
    {
      key: "email",
      name: "Email",
      sortable: true,
      selector: (row) => row?.email || "-",
    },
    {
      key: "phonenumber",
      name: "Phone Number",
      sortable: true,
      selector: (row) => row?.phonenumber || "-",
    },
    {
      key: "status",
      name: "Status",
      sortable: true,
      selector: (row) => (
        <div
          className={`${
            activeFilter == 1
              ? "column-status-pending"
              : "column-status-completed"
          }`}
        >
          {activeFilter == 1 ? "Pending" : "Approved"}
        </div>
      ),
    },
    {
      key: "action",
      name: "Action",
      selector: (row) => (
        <div
          className="theme-action view-column cursor"
          onClick={() => {
            setIsOpen({ type: modalType.view, open: true });
          }}
        >
          View
          {/* <FaRegEye
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.view, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
          {/* <TbUserCog
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.block, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
          {/* <RiDeleteBin7Line
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.delete, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
        </div>
      ),
    },
  ];
};
export const reportsColumns = (setIsOpen, dispatch, activeFilter) => {
  return [
    {
      key: "name",
      name: "Name",
      sortable: true,
      width: "300px",

      selector: (row) => (
        <div className="name-column">
          <img
            src={elipse}
            alt="User"
            style={{
              width: "40px",
              height: "40px",
              marginRight: "10px",
              borderRadius: "25px",
            }}
          />
          {row?.username || "-"}
        </div>
      ),
    },
    {
      key: "email",
      name: "Email",
      sortable: true,
      width: "300px",

      selector: (row) => row?.email || "-",
    },
    {
      key: "phonenumber",
      name: "Phone Number",
      sortable: true,
      width: "250px",

      selector: (row) => row?.phonenumber || "-",
    },
    {
      key: "status",
      name: "Status",
      sortable: true,
      width: "200px",

      selector: (row) => (
        <div
          className={`${
            activeFilter == 1
              ? "column-status-pending"
              : "column-status-completed"
          }`}
        >
          {activeFilter == 1 ? "Pending" : "Approved"}
        </div>
      ),
    },
    {
      key: "action",
      name: "Action",
      selector: (row) => (
        <div
          className="theme-action view-column cursor"
          onClick={() => {
            setIsOpen({ type: modalType.view, open: true });
          }}
        >
          View
          {/* <FaRegEye
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.view, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
          {/* <TbUserCog
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.block, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
          {/* <RiDeleteBin7Line
            className="icon"
            onClick={() => {
              setIsOpen({ type: modalType.delete, open: true });
              dispatch(setSelectedData(row));
            }}
          /> */}
        </div>
      ),
    },
  ];
};
const ActionMenu = ({ row, setIsOpen, setIsBlockOpen }) => {
  const [isActionOpen, setIsActionOpen] = useState(false);

  const toggleMenu = () => {
    setIsActionOpen(!isActionOpen);
  };

  const handleOptionClick = (option) => {
    switch (option) {
      case "view":
        setIsOpen({ type: modalType.view, open: true });
        break;
      case "block":
        setIsOpen({ type: modalType.block, open: true });
        break;
      default:
        setIsOpen({ type: null, open: false });
        break;
    }
    setIsActionOpen(true);
  };

  return (
    <div className="action-menus">
      <div className="cursor" onClick={toggleMenu}>
        ...
      </div>
      {isActionOpen && (
        <div className="dropdown-menu-item">
          <div
            className="dropdown-item cursor"
            onClick={() => handleOptionClick("view")}
          >
            View Details
          </div>
          <div
            className="dropdown-item cursor"
            onClick={() => handleOptionClick("block")}
          >
            Block Inventory
          </div>
          <div
            className="dropdown-item cursor danger"
            style={{ color: "red" }}
            onClick={() => handleOptionClick("block")}
          >
            Block
          </div>
        </div>
      )}
    </div>
  );
};
