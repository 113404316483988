import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    user: null,
    isLoading: true,
};

export const authSlice = createSlice({
    name: "authInfo",
    initialState,
    reducers: {
        setUserObject: (state, action) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
        },
    },
});

export const { setUserObject, logout } = authSlice.actions;
export const authInfoReducer = authSlice.reducer;