import React from "react";
import { GetTokenFromLocalStorage } from "../utils/helper";
import { Navigate, Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
  const token = GetTokenFromLocalStorage();
  // return <Outlet />;
  return <>{token ? <Outlet /> : <Navigate to="/" />}</>;
};

export default ProtectedRoutes;
