import React from "react";
import user from "../assets/img/user.png";
import one from "../assets/img/1.png";
import two from "../assets/img/2.png";
import three from "../assets/img/3.png";
import four from "../assets/img/4.png";
import { validation } from "../utils/app-constants";
import ThemeInput from "./input/ThemeInput";
import { Form, Button } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { successMsg } from "../constants/msgs";
import ThemeTextArea from "./input/ThemeTextArea";

const ReportsDetailModal = () => {
    const {
        register,
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({ mode: "onChange" });

    const data = [
        {
            id: 1,
            image: user,
            name: "Jordon Doe",
            role: "Customer",
            userId: "ID: 651535",
            contactInfo: "nathan.roberts@example.com",
            products: [
                {
                    id: 1,
                    name: "Products",
                    image: one,
                },
                {
                    id: 2,
                    name: "Products",
                    image: two,
                },
                {
                    id: 3,
                    name: "Products",
                    image: three,
                },
                {
                    id: 4,
                    name: "Products",
                    image: four,
                },
            ],
        },
    ];
    const onSubmit = (data) => {
        // console.log(data);
    }
    const handleApprove = () => {
        successMsg("Resolved Successfully");
    }
    return (
        <>
            <div className="customer-detail-modal report-detail-modal">
                {data &&
                    data?.map((dt) => (
                        <>
                            <div className="user-image mb-3 image-fluid">
                                <img src={dt?.image} />
                            </div>
                            <div className="detail-sec d-flex">
                                <div className="headings">
                                    <p>Name: </p>
                                    <p>User Role: </p>
                                    <p>User ID: </p>
                                    <p>Contact Info</p>
                                </div>
                                <div className={"details"}>
                                    <p>{dt?.name}</p>
                                    <p>{dt?.role}</p>
                                    <p>{dt?.userId}</p>
                                    <p>{dt?.contactInfo}</p>
                                </div>
                            </div>
                            <div className="horizontol-line mt-4"></div>
                            <div className="last-sec mt-3">
                                <div className="heading">
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <ThemeTextArea
                                            name="description"
                                            className="desc"
                                            control={control}
                                            errors={errors}
                                            label="Description"
                                            // placeholder="Description..."
                                            type="text"
                                            maxLength={validation.descriptionMax}
                                        />

                                        <ThemeInput
                                            name="response`"
                                            control={control}
                                            errors={errors}
                                            label="Add Reason"
                                            placeholder="Add Response"
                                            type="texts"
                                            className='modal-input'
                                            maxLength={validation.text}
                                        />
                                        <div className="submit-btns d-flex">
                                            <Button
                                                type="submit"
                                                // disabled={isLoading}
                                                className="btn-solid btn-pink mt-4 btn-submit"
                                            >
                                                Submint Response
                                            </Button>

                                            <Button
                                                type="submit"
                                                onClick={handleApprove}
                                                // disabled={isLoading}
                                                className="btn-solid btn-approve mt-4 btn-submit"
                                            >
                                                Mark as Resolved
                                            </Button>

                                        </div>
                                    </Form>
                                </div>
                            </div>
                        </>
                    ))}
            </div>
        </>
    );
};
export default ReportsDetailModal;
