import React, { useEffect, useMemo, useRef, useState } from "react";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { FiSend } from "react-icons/fi";
import { IoIosArrowBack } from "react-icons/io";
import { Form, InputGroup, Spinner } from "react-bootstrap";
import { CardTitle } from "./Component";
import userImg from "../../assets/img/user.png";
import { useSocket } from "../../socket/socketConext";
import { useChatContext } from "../../context/ChatContext";
import { useSelector } from "react-redux";
import { errorMsg } from "../../constants/msgs";
import { GetUserFromLocalStorage } from "../../utils/helper";

const Inbox = ({ isMobile = false }) => {
    const socket = useSocket()
    const isSocketConnected = useSelector(state => state.socket.isSocketConnected)
    // const { isLoading, user } = useSelector(state => state.authInfo)
    const user = GetUserFromLocalStorage()
    const { chatRoom, setChatRoom, updateLastMessagePayload } = useChatContext()



    const INITIAL_CHAT_HISTORY = useMemo(() => {
        return {
            isError: false,
            isLoading: true,
            data: [],
        };
    }, []);

    const [chatHistory, setChatHistory] = useState(INITIAL_CHAT_HISTORY)
    console.log(chatHistory, 'chatHistory00000');

    useEffect(() => {
        if (!chatHistory.isLoading) {
            setChatHistory(INITIAL_CHAT_HISTORY)
        }

        if (!_.isEmpty(chatRoom)) {
            socket.emit("_loadChatHistory", { chat_room_slug: chatRoom.chat_room_slug })
        }
    }, [chatRoom])

    useEffect(() => {
        if (isSocketConnected) {
            console.log("Listening for loadChatHistory_");

            const slug = {
                chat_room_slug: chatRoom,
            };

            const handleChatHistory = (data) => {
                setChatHistory(data);
            };

            socket.on('loadChatHistory_', handleChatHistory);

            return () => {
                if (isSocketConnected) {
                    // socket.dispose('loadChatHistory_', handleChatHistory);
                    console.log("Stopped listening to loadChatHistory_");
                }
            };
        }
    }, [isSocketConnected, socket]);


    useEffect(() => {
        if (isSocketConnected) {
            socket.on('loadChatHistory_', (data) => {
                setChatHistory(prev => {
                    return {
                        ...prev,
                        isLoading: false,
                        data: data?.data || []
                    }
                })
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('loadChatHistory_')
            }
        }

    }, [isSocketConnected])

    useEffect(() => {
        if (isSocketConnected) {
            socket.on('receivedMessage_', (data) => {
                const message = data?.data

                if (message?.chat_room_slug !== chatRoom.chat_room_slug) return;
                console.log(data, message, chatRoom);

                if (_.isUndefined(data?.payload?.message_uuid)) {
                    setChatHistory(prev => {
                        return {
                            ...prev,
                            data: [
                                ...prev?.data,
                                message
                            ]
                        }
                    })
                }
                else {
                    setChatHistory(prev => {
                        return {
                            ...prev,
                            data: [
                                message, // Add new message at the beginning if updating an existing message
                                ...prev?.data.filter(item => item.message_uuid !== data?.payload.message_uuid)
                            ]
                        }
                    })
                }

                socket.emit('_resetMessageCount')
            })
        }

        return () => {
            if (isSocketConnected) {
                socket.dispose('receivedMessage_')
            }
        }

    }, [isSocketConnected, chatRoom])



    const scrollChatContainer = () => {
        const container = document.getElementById('chatContainer')
        container.scroll({ top: container.scrollHeight, behavior: 'smooth' });
    }

    useEffect(() => {
        scrollChatContainer()
    }, [chatHistory?.data.length])

    const ref = useRef(null);
    const messageRef = useRef(null);

    const handleSendMessage = async () => {
        const message = messageRef.current.value;
        console.log(message, 'BOAD');

        if (!message) return;

        try {
            const payload = {}
            payload.message_type = 'TEXT';
            payload.message = message
            payload.message_slug = uuidv4()
            payload.chat_room_slug = chatRoom?.chat_room_slug
            setChatHistory(prev => {
                return {
                    ...prev,
                    data: [
                        {
                            ...payload,
                            user_slug: user.slug,
                            user_name: user.name,
                            user_image: user.image_url,
                            message_timestamp: new Date(),
                        },
                        ...prev?.data
                    ]
                }
            })
            socket.emit('_sendMessage', payload);
            messageRef.current.value = ''
        }
        catch (err) {
            errorMsg(err?.message);
        }
    }

    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return `${date.getHours()}:${String(date.getMinutes()).padStart(2, "0")}`;
    };

    return (
        <div className={"Inbox"}>
            <div className={"titleContainer"}>
                <div className={"userContainer"}>
                    <div className={"imageContainer"}>
                        {isMobile ? (
                            <span className={"backButton"} onClick={() => console.log("Back button clicked")}>
                                <IoIosArrowBack />
                            </span>
                        ) : (
                            ""
                        )}

                        <img className={"image"} src={chatRoom?.image_url} alt="User Profile" />
                        <span className={"online"}></span>
                    </div>
                    <div className={"nameContainer"}>
                        <CardTitle text={chatRoom?.room_name} classes="lc-1" />
                    </div>
                </div>
            </div>
            <div className={"chatContainer"} id="chatContainer">
                {chatHistory?.isLoading ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center text-white">
                        <Spinner />
                    </div>
                ) : _.isEmpty(chatHistory?.data) ? (
                    <div className="w-100 h-100 d-flex align-items-center justify-content-center">
                        <p className="m-0 text-white fs-5">Chat Messages Not Found</p>
                    </div>
                ) : (
                    chatHistory?.data?.map((item, index) => {
                        const isMe = item["user_slug"] === user?.slug;
                        return (
                            <div
                                className={`${"chat"} ${isMe ? "me" : ""}`}
                                key={item.id}
                                ref={index === chatHistory?.data.length - 1 ? ref : null}
                            >
                                <span className={"info"}>{formatTimestamp(item?.message_timestamp)}</span>
                                <p className={`${"message"} ${isMe ? "me" : ""}`}>{item?.message}</p>
                            </div>
                        );
                    })
                )}
            </div>

            <div className={"messageContainer"}>
                <InputGroup>
                    <Form.Control
                        size="lg"
                        className={`mt-1 mb-2  messageBar`}
                        placeholder={"Type a message"}
                        type={"text"}
                        ref={messageRef}
                        onKeyDown={(e) => {
                            if (e.keyCode === 13) {
                                handleSendMessage();
                                e.stopPropagation();
                            }
                        }}
                    />
                </InputGroup>
                <button className={`${"sendButton"} `} onClick={handleSendMessage}>
                    <FiSend />
                </button>
            </div>
        </div>
    );
};

export default Inbox;
