import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import AuthLayout from "../layouts/AuthLayout";
import SigninForm from "../components/auth/SigninForm";
import DashboardLayout from "../layouts/DashboardLayout";
import DashboardPage from "../pages/DashboardPage";
import NotFound from "../pages/NotFound";
import UserPage from "../pages/UserPage";
import ChangePasswordPage from "../pages/ChangePasswordPage";
import ContentManagementPage from "../pages/ContentManagementPage";
import PublicRoutes from "../navigation/PublicRoutes";
import ProtectedRoutes from "../navigation/ProtectedRoutes";
import Faqs from "../pages/Faqs.jsx";
import ReportTypes from "../pages/ReportTypes.jsx";
import SendPushNotifications from "../pages/SendPushNotifications.jsx";
import OrderPage from "../pages/OrdersPage.jsx";
import CustomerPage from "../pages/CustomerPage.jsx";
import DistributorsPage from "../pages/DistributorsPage.jsx";
import ShopOwnerPage from "../pages/shopOwner.jsx";
import RidersPage from "../pages/RidersPage.jsx";
import ReportsPage from "../pages/Reports.jsx";
import Message from "../pages/Messages.jsx";

const Navigation = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* PUBLIC ROUTES */}
        <Route element={<PublicRoutes />}>
          <Route path="/" element={<AuthLayout children={<SigninForm />} />} />
        </Route>

        {/* PROTECTED ROUTES */}
        <Route element={<ProtectedRoutes />}>
          <Route
            path="/admin/dashboard"
            element={<DashboardLayout children={<DashboardPage />} />}
          />

          {/* <Route
            path="/admin/dashboard"
            element={<DashboardLayout children={<DashboardPage />} />}
          />
          <Route
            path="/admin/orders"
            element={<DashboardLayout children={<OrderPage />} />}
          />
          <Route
            path="/admin/customers"
            element={<DashboardLayout children={<CustomerPage />} />}
          />
          <Route
            path="/admin/distributors"
            element={<DashboardLayout children={<DistributorsPage />} />}
          />
          <Route
            path="/admin/shopowners"
            element={<DashboardLayout children={<ShopOwnerPage />} />}
          />
          <Route
            path="/admin/riders"
            element={<DashboardLayout children={<RidersPage />} />}
          />

          <Route
            path="/admin/user"
            element={<DashboardLayout children={<UserPage />} />}
          />
          <Route
            path="/admin/change-password"
            element={<DashboardLayout children={<ChangePasswordPage />} />}
          />
          <Route
            path="/admin/page/:slug"
            element={<DashboardLayout children={<ContentManagementPage />} />}
          />
          <Route
            path="/admin/faqs"
            element={<DashboardLayout children={<Faqs />} />}
          />
          <Route
            path="/admin/report-types"
            element={<DashboardLayout children={<ReportTypes />} />}
          />
          <Route
            path="/admin/reports"
            element={<DashboardLayout children={<ReportsPage />} />}
          /> */}
          <Route
            path="/admin/chat-support"
            element={<DashboardLayout children={<Message />} />}
          />
        </Route>
        {/* KEEP IT AT THE END */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
};

export default Navigation;
