import React, { useEffect } from 'react'
import Navigation from './navigation/Navigation'
import { ToastContainer } from 'react-toastify';

const App = () => {
  return (
    <>
      <ToastContainer />
      <Navigation />
    </>
  )
}

export default App