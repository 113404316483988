import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web
import persistConfig from './persistConfig';
import { authInfoReducer } from './services/authInfo';
import { socketReducer } from './services/socketSlice';
import { apiSlice } from './services/apiSlice';
import { authApi } from './apis/authApi';


const rootReducer = combineReducers({
    authInfo: authInfoReducer,
    socket: socketReducer,
    [apiSlice.reducerPath]: apiSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;