import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import MessageWrapper from "../components/messaegs/InboxWrapper";
import ChatContextProvider from "../context/ChatContext";
import { SocketProvider } from "../socket/socketConext";
import { resetSocketConnection } from "../store/services/socketSlice";

const Message = () => {

    const dispatch = useDispatch()

    useEffect(() => {
        return () => {
            // Cleanup: Reset socket connection state
            dispatch(resetSocketConnection());
        };
    }, [dispatch]);

    return (
        <SocketProvider>
            <ChatContextProvider>
                <MessageWrapper />
            </ChatContextProvider>
        </SocketProvider>
    )
}
export default Message