import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isSocketConnected: false,
};

export const socketSlice = createSlice({
    name: "socket",
    initialState,
    reducers: {
        socketConntected: (state) => {
            state.isSocketConnected = true;
        },
        resetSocketConnection: (state) => {
            state.isSocketConnected = false;
        },
    },
});

export const { socketConntected, resetSocketConnection } = socketSlice.actions;
export const socketReducer = socketSlice.reducer;